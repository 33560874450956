const BI_AMOUNT_MULTIPLIER = 100000;

export const toBiAmount = (amount: number) => Math.round(amount * BI_AMOUNT_MULTIPLIER);

export enum CheckoutOrigin {
  checkout = 'checkout',
  paymentRequest = 'payment-request',
}

export const getUniques = (arr: string[]): string[] => {
  return [...new Set([...arr])];
};
