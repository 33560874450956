import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {PaymentRequestSettingsApi} from '../../apis/paymentRequest/PaymentRequestSettingsApi';
import {StoreCheckoutSettings} from '@wix/wixstores-graphql-schema-node';
import {PaymentRequestSettingsModel} from '../../models/paymentRequest/PaymentRequestSettings.model';
import {CheckoutOrigin} from '../../utils/common/bi.util.common';

export class PaymentRequestSettingsService {
  private readonly siteStore: SiteStore;
  private readonly paymentRequestSettingsApi: PaymentRequestSettingsApi;
  public paymentRequestSettings!: PaymentRequestSettingsModel;

  constructor({siteStore}: {siteStore: SiteStore}) {
    this.siteStore = siteStore;
    this.paymentRequestSettingsApi = new PaymentRequestSettingsApi({siteStore, origin: CheckoutOrigin.paymentRequest});
  }

  public async fetchSiteSettings(): Promise<void> {
    const paymentRequestSettings: StoreCheckoutSettings =
      await this.paymentRequestSettingsApi.getPaymentRequestSettings();
    this.paymentRequestSettings = new PaymentRequestSettingsModel({
      paymentRequestSettings,
    });
  }

  public async init(): Promise<void> {
    await this.fetchSiteSettings();
  }
}
